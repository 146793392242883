var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "solutions-area section-width ptb-100" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "section-title" }, [
        _c("h2", [_vm._v(_vm._s(_vm.$t("navbar.menu_1_5")))]),
        _c("p", [
          _vm._v(
            " Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. "
          )
        ])
      ]),
      _c("section", { staticClass: "blog-area pb-70" }, [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "facility-section block1" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "card mb-3" }, [
                _c("div", { staticClass: "row no-gutters" }, [
                  _vm._m(0),
                  _c("div", { staticClass: "col-md-8" }, [
                    _c("div", { staticClass: "card-body text-left" }, [
                      _c("h5", { staticClass: "card-title" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$t("section_1.item_13.title")) + " "
                        )
                      ]),
                      _c("p", {
                        staticClass: "card-text",
                        domProps: {
                          innerHTML: _vm._s(_vm.$t("section_1.item_13.content"))
                        }
                      }),
                      _vm._m(1)
                    ])
                  ])
                ])
              ]),
              _c("div", { staticClass: "card mb-3" }, [
                _c("div", { staticClass: "row no-gutters flex-row-reverse" }, [
                  _vm._m(2),
                  _c("div", { staticClass: "col-md-8" }, [
                    _c("div", { staticClass: "card-body text-left" }, [
                      _c("h5", { staticClass: "card-title" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$t("section_1.item_2.title")) + " "
                        )
                      ]),
                      _c("p", {
                        staticClass: "card-text",
                        domProps: {
                          innerHTML: _vm._s(_vm.$t("section_1.item_2.content"))
                        }
                      }),
                      _vm._m(3)
                    ])
                  ])
                ])
              ]),
              _c("div", { staticClass: "card mb-3" }, [
                _c("div", { staticClass: "row no-gutters" }, [
                  _vm._m(4),
                  _c("div", { staticClass: "col-md-8" }, [
                    _c("div", { staticClass: "card-body text-left" }, [
                      _c("h5", { staticClass: "card-title" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$t("section_1.item_12.title")) + " "
                        )
                      ]),
                      _c("p", {
                        staticClass: "card-text",
                        domProps: {
                          innerHTML: _vm._s(_vm.$t("section_1.item_12.content"))
                        }
                      }),
                      _vm._m(5)
                    ])
                  ])
                ])
              ]),
              _c("div", { staticClass: "card mb-3" }, [
                _c("div", { staticClass: "row no-gutters flex-row-reverse" }, [
                  _vm._m(6),
                  _c("div", { staticClass: "col-md-8" }, [
                    _c("div", { staticClass: "card-body text-left" }, [
                      _c("h5", { staticClass: "card-title" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$t("section_1.item_8.title")) + " "
                        )
                      ]),
                      _c("p", {
                        staticClass: "card-text",
                        domProps: {
                          innerHTML: _vm._s(_vm.$t("section_1.item_8.content"))
                        }
                      }),
                      _vm._m(7)
                    ])
                  ])
                ])
              ]),
              _c("div", { staticClass: "card mb-3" }, [
                _c("div", { staticClass: "row no-gutters" }, [
                  _vm._m(8),
                  _c("div", { staticClass: "col-md-8" }, [
                    _c("div", { staticClass: "card-body text-left" }, [
                      _c("h5", { staticClass: "card-title" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$t("section_1.item_11.title")) + " "
                        )
                      ]),
                      _c("p", {
                        staticClass: "card-text",
                        domProps: {
                          innerHTML: _vm._s(_vm.$t("section_1.item_11.content"))
                        }
                      }),
                      _vm._m(9)
                    ])
                  ])
                ])
              ]),
              _c("div", { staticClass: "card mb-3" }, [
                _c("div", { staticClass: "row no-gutters flex-row-reverse" }, [
                  _vm._m(10),
                  _c("div", { staticClass: "col-md-8" }, [
                    _c("div", { staticClass: "card-body text-left" }, [
                      _c("h5", { staticClass: "card-title" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$t("section_1.item_1.title")) + " "
                        )
                      ]),
                      _c("p", {
                        staticClass: "card-text",
                        domProps: {
                          innerHTML: _vm._s(_vm.$t("section_1.item_1.content"))
                        }
                      }),
                      _vm._m(11)
                    ])
                  ])
                ])
              ]),
              _c("div", { staticClass: "card mb-3" }, [
                _c("div", { staticClass: "row no-gutters" }, [
                  _vm._m(12),
                  _c("div", { staticClass: "col-md-8" }, [
                    _c("div", { staticClass: "card-body text-left" }, [
                      _c("h5", { staticClass: "card-title" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$t("section_1.item_15.title")) + " "
                        )
                      ]),
                      _c("p", {
                        staticClass: "card-text",
                        domProps: {
                          innerHTML: _vm._s(_vm.$t("section_1.item_15.content"))
                        }
                      }),
                      _vm._m(13)
                    ])
                  ])
                ])
              ]),
              _c("div", { staticClass: "card mb-3" }, [
                _c("div", { staticClass: "row no-gutters flex-row-reverse" }, [
                  _vm._m(14),
                  _c("div", { staticClass: "col-md-8" }, [
                    _c("div", { staticClass: "card-body text-left" }, [
                      _c("h5", { staticClass: "card-title" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$t("section_1.item_9.title")) + " "
                        )
                      ]),
                      _c("p", {
                        staticClass: "card-text",
                        domProps: {
                          innerHTML: _vm._s(_vm.$t("section_1.item_9.content"))
                        }
                      }),
                      _vm._m(15)
                    ])
                  ])
                ])
              ]),
              _c("div", { staticClass: "card mb-3" }, [
                _c("div", { staticClass: "row no-gutters" }, [
                  _vm._m(16),
                  _c("div", { staticClass: "col-md-8" }, [
                    _c("div", { staticClass: "card-body text-left" }, [
                      _c("h5", { staticClass: "card-title" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$t("section_1.item_10.title")) + " "
                        )
                      ]),
                      _c("p", {
                        staticClass: "card-text",
                        domProps: {
                          innerHTML: _vm._s(_vm.$t("section_1.item_10.content"))
                        }
                      }),
                      _vm._m(17)
                    ])
                  ])
                ])
              ]),
              _c("div", { staticClass: "card mb-3" }, [
                _c("div", { staticClass: "row no-gutters flex-row-reverse" }, [
                  _vm._m(18),
                  _c("div", { staticClass: "col-md-8" }, [
                    _c("div", { staticClass: "card-body text-left" }, [
                      _c("h5", { staticClass: "card-title" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$t("section_1.item_5.title")) + " "
                        )
                      ]),
                      _c("p", {
                        staticClass: "card-text",
                        domProps: {
                          innerHTML: _vm._s(_vm.$t("section_1.item_5.content"))
                        }
                      }),
                      _vm._m(19)
                    ])
                  ])
                ])
              ]),
              _c("div", { staticClass: "card mb-3" }, [
                _c("div", { staticClass: "row no-gutters" }, [
                  _vm._m(20),
                  _c("div", { staticClass: "col-md-8" }, [
                    _c("div", { staticClass: "card-body text-left" }, [
                      _c("h5", { staticClass: "card-title" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$t("section_1.item_3.title")) + " "
                        )
                      ]),
                      _c("p", {
                        staticClass: "card-text",
                        domProps: {
                          innerHTML: _vm._s(_vm.$t("section_1.item_3.content"))
                        }
                      }),
                      _vm._m(21)
                    ])
                  ])
                ])
              ]),
              _c("div", { staticClass: "card mb-3" }, [
                _c("div", { staticClass: "row no-gutters flex-row-reverse" }, [
                  _vm._m(22),
                  _c("div", { staticClass: "col-md-8" }, [
                    _c("div", { staticClass: "card-body text-left" }, [
                      _c("h5", { staticClass: "card-title" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$t("section_1.item_16.title")) + " "
                        )
                      ]),
                      _c("p", {
                        staticClass: "card-text",
                        domProps: {
                          innerHTML: _vm._s(_vm.$t("section_1.item_16.content"))
                        }
                      }),
                      _vm._m(23)
                    ])
                  ])
                ])
              ]),
              _c("div", { staticClass: "card mb-3" }, [
                _c("div", { staticClass: "row no-gutters" }, [
                  _vm._m(24),
                  _c("div", { staticClass: "col-md-8" }, [
                    _c("div", { staticClass: "card-body text-left" }, [
                      _c("h5", { staticClass: "card-title" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$t("section_1.item_4.title")) + " "
                        )
                      ]),
                      _c("p", {
                        staticClass: "card-text",
                        domProps: {
                          innerHTML: _vm._s(_vm.$t("section_1.item_4.content"))
                        }
                      }),
                      _vm._m(25)
                    ])
                  ])
                ])
              ]),
              _c("div", { staticClass: "card mb-3" }, [
                _c("div", { staticClass: "row no-gutters flex-row-reverse" }, [
                  _vm._m(26),
                  _c("div", { staticClass: "col-md-8" }, [
                    _c("div", { staticClass: "card-body text-left" }, [
                      _c("h5", { staticClass: "card-title" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$t("section_1.item_14.title")) + " "
                        )
                      ]),
                      _c("p", {
                        staticClass: "card-text",
                        domProps: {
                          innerHTML: _vm._s(_vm.$t("section_1.item_14.content"))
                        }
                      }),
                      _vm._m(27)
                    ])
                  ])
                ])
              ]),
              _c("div", { staticClass: "card mb-3" }, [
                _c("div", { staticClass: "row no-gutters" }, [
                  _vm._m(28),
                  _c("div", { staticClass: "col-md-8" }, [
                    _c("div", { staticClass: "card-body text-left" }, [
                      _c("h5", { staticClass: "card-title" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$t("section_1.item_7.title")) + " "
                        )
                      ]),
                      _c("p", {
                        staticClass: "card-text",
                        domProps: {
                          innerHTML: _vm._s(_vm.$t("section_1.item_7.content"))
                        }
                      }),
                      _vm._m(29)
                    ])
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-4" }, [
      _c("img", {
        attrs: {
          src: require("assets/project_images/accommodation/taiurbanresort.jpg"),
          alt: "Image"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "card-text" }, [
      _c("small", { staticClass: "text-muted" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-4" }, [
      _c("img", {
        attrs: {
          src: require("assets/project_images/accommodation/SilksClub.jpg"),
          alt: "Image"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "card-text" }, [
      _c("small", { staticClass: "text-muted" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-4" }, [
      _c("img", {
        attrs: {
          src: require("assets/project_images/accommodation/InterContinental.jpg"),
          alt: "Image"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "card-text" }, [
      _c("small", { staticClass: "text-muted" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-4" }, [
      _c("img", {
        attrs: {
          src: require("assets/project_images/accommodation/COZZI.jpg"),
          alt: "Image"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "card-text" }, [
      _c("small", { staticClass: "text-muted" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-4" }, [
      _c("img", {
        attrs: {
          src: require("assets/project_images/accommodation/cityRestSunshine.jpg"),
          alt: "Image"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "card-text" }, [
      _c("small", { staticClass: "text-muted" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-4" }, [
      _c("img", {
        attrs: {
          src: require("assets/project_images/accommodation/cityRestHilai.jpg"),
          alt: "Image"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "card-text" }, [
      _c("small", { staticClass: "text-muted" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-4" }, [
      _c("img", {
        attrs: {
          src: require("assets/project_images/accommodation/han-hsien.jpg"),
          alt: "Image"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "card-text" }, [
      _c("small", { staticClass: "text-muted" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-4" }, [
      _c("img", {
        attrs: {
          src: require("assets/project_images/accommodation/citysuites.jpg"),
          alt: "Image"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "card-text" }, [
      _c("small", { staticClass: "text-muted" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-4" }, [
      _c("img", {
        attrs: {
          src: require("assets/project_images/accommodation/CDCK.jpg"),
          alt: "Image"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "card-text" }, [
      _c("small", { staticClass: "text-muted" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-4" }, [
      _c("img", {
        attrs: {
          src: require("assets/project_images/accommodation/thelees.jpg"),
          alt: "Image"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "card-text" }, [
      _c("small", { staticClass: "text-muted" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-4" }, [
      _c("img", {
        attrs: {
          src: require("assets/project_images/accommodation/Indigo.jpg"),
          alt: "Image"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "card-text" }, [
      _c("small", { staticClass: "text-muted" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-4" }, [
      _c("img", {
        attrs: {
          src: require("assets/project_images/accommodation/Hotel_dua.jpg"),
          alt: "Image"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "card-text" }, [
      _c("small", { staticClass: "text-muted" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-4" }, [
      _c("img", {
        attrs: {
          src: require("assets/project_images/accommodation/Howard.jpg"),
          alt: "Image"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "card-text" }, [
      _c("small", { staticClass: "text-muted" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-4" }, [
      _c("img", {
        attrs: {
          src: require("assets/project_images/accommodation/marriott.jpg"),
          alt: "Image"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "card-text" }, [
      _c("small", { staticClass: "text-muted" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-4" }, [
      _c("img", {
        attrs: {
          src: require("assets/project_images/accommodation/H2O.png"),
          alt: "Image"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "card-text" }, [
      _c("small", { staticClass: "text-muted" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }