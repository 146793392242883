<template>
  <div class="solutions-area section-width ptb-100">
    <div class="container">
      <div class="section-title">
        <h2>{{ $t("navbar.menu_1_5") }}</h2>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incidiunt labore et dolore magna aliqua. Quis ipsum
          suspendisse ultrices gravida.
        </p>
      </div>
      <section class="blog-area pb-70">
        <div class="container">
          <div class="facility-section block1">
            <div class="row">
              <div class="card mb-3">
                <div class="row no-gutters">
                  <div class="col-md-4">
                    <img
                      src="~assets/project_images/accommodation/taiurbanresort.jpg"
                      alt="Image"
                    />
                  </div>
                  <div class="col-md-8">
                    <div class="card-body text-left">
                      <h5 class="card-title">
                        {{ $t("section_1.item_13.title") }}
                      </h5>
                      <p
                        class="card-text"
                        v-html="$t('section_1.item_13.content')"
                      ></p>
                      <p class="card-text">
                        <small class="text-muted"></small>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card mb-3">
                <div class="row no-gutters flex-row-reverse">
                  <div class="col-md-4">
                    <img
                      src="~assets/project_images/accommodation/SilksClub.jpg"
                      alt="Image"
                    />
                  </div>
                  <div class="col-md-8">
                    <div class="card-body text-left">
                      <h5 class="card-title">
                        {{ $t("section_1.item_2.title") }}
                      </h5>
                      <p
                        class="card-text"
                        v-html="$t('section_1.item_2.content')"
                      ></p>
                      <p class="card-text">
                        <small class="text-muted"></small>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card mb-3">
                <div class="row no-gutters">
                  <div class="col-md-4">
                    <img
                      src="~assets/project_images/accommodation/InterContinental.jpg"
                      alt="Image"
                    />
                  </div>
                  <div class="col-md-8">
                    <div class="card-body text-left">
                      <h5 class="card-title">
                        {{ $t("section_1.item_12.title") }}
                      </h5>
                      <p
                        class="card-text"
                        v-html="$t('section_1.item_12.content')"
                      ></p>
                      <p class="card-text">
                        <small class="text-muted"></small>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card mb-3">
                <div class="row no-gutters flex-row-reverse">
                  <div class="col-md-4">
                    <img
                      src="~assets/project_images/accommodation/COZZI.jpg"
                      alt="Image"
                    />
                  </div>
                  <div class="col-md-8">
                    <div class="card-body text-left">
                      <h5 class="card-title">
                        {{ $t("section_1.item_8.title") }}
                      </h5>
                      <p
                        class="card-text"
                        v-html="$t('section_1.item_8.content')"
                      ></p>
                      <p class="card-text">
                        <small class="text-muted"></small>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card mb-3">
                <div class="row no-gutters">
                  <div class="col-md-4">
                    <img
                      src="~assets/project_images/accommodation/cityRestSunshine.jpg"
                      alt="Image"
                    />
                  </div>
                  <div class="col-md-8">
                    <div class="card-body text-left">
                      <h5 class="card-title">
                        {{ $t("section_1.item_11.title") }}
                      </h5>
                      <p
                        class="card-text"
                        v-html="$t('section_1.item_11.content')"
                      ></p>
                      <p class="card-text">
                        <small class="text-muted"></small>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card mb-3">
                <div class="row no-gutters flex-row-reverse">
                  <div class="col-md-4">
                    <img
                      src="~assets/project_images/accommodation/cityRestHilai.jpg"
                      alt="Image"
                    />
                  </div>
                  <div class="col-md-8">
                    <div class="card-body text-left">
                      <h5 class="card-title">
                        {{ $t("section_1.item_1.title") }}
                      </h5>
                      <p
                        class="card-text"
                        v-html="$t('section_1.item_1.content')"
                      ></p>
                      <p class="card-text">
                        <small class="text-muted"></small>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card mb-3">
                <div class="row no-gutters">
                  <div class="col-md-4">
                    <img
                      src="~assets/project_images/accommodation/han-hsien.jpg"
                      alt="Image"
                    />
                  </div>
                  <div class="col-md-8">
                    <div class="card-body text-left">
                      <h5 class="card-title">
                        {{ $t("section_1.item_15.title") }}
                      </h5>
                      <p
                        class="card-text"
                        v-html="$t('section_1.item_15.content')"
                      ></p>
                      <p class="card-text">
                        <small class="text-muted"></small>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card mb-3">
                <div class="row no-gutters flex-row-reverse">
                  <div class="col-md-4">
                    <img
                      src="~assets/project_images/accommodation/citysuites.jpg"
                      alt="Image"
                    />
                  </div>
                  <div class="col-md-8">
                    <div class="card-body text-left">
                      <h5 class="card-title">
                        {{ $t("section_1.item_9.title") }}
                      </h5>
                      <p
                        class="card-text"
                        v-html="$t('section_1.item_9.content')"
                      ></p>
                      <p class="card-text">
                        <small class="text-muted"></small>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card mb-3">
                <div class="row no-gutters">
                  <div class="col-md-4">
                    <img
                      src="~assets/project_images/accommodation/CDCK.jpg"
                      alt="Image"
                    />
                  </div>
                  <div class="col-md-8">
                    <div class="card-body text-left">
                      <h5 class="card-title">
                        {{ $t("section_1.item_10.title") }}
                      </h5>
                      <p
                        class="card-text"
                        v-html="$t('section_1.item_10.content')"
                      ></p>
                      <p class="card-text">
                        <small class="text-muted"></small>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card mb-3">
                <div class="row no-gutters flex-row-reverse">
                  <div class="col-md-4">
                    <img
                      src="~assets/project_images/accommodation/thelees.jpg"
                      alt="Image"
                    />
                  </div>
                  <div class="col-md-8">
                    <div class="card-body text-left">
                      <h5 class="card-title">
                        {{ $t("section_1.item_5.title") }}
                      </h5>
                      <p
                        class="card-text"
                        v-html="$t('section_1.item_5.content')"
                      ></p>
                      <p class="card-text">
                        <small class="text-muted"></small>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card mb-3">
                <div class="row no-gutters">
                  <div class="col-md-4">
                    <img
                      src="~assets/project_images/accommodation/Indigo.jpg"
                      alt="Image"
                    />
                  </div>
                  <div class="col-md-8">
                    <div class="card-body text-left">
                      <h5 class="card-title">
                        {{ $t("section_1.item_3.title") }}
                      </h5>
                      <p
                        class="card-text"
                        v-html="$t('section_1.item_3.content')"
                      ></p>
                      <p class="card-text">
                        <small class="text-muted"></small>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card mb-3">
                <div class="row no-gutters flex-row-reverse">
                  <div class="col-md-4">
                    <img
                      src="~assets/project_images/accommodation/Hotel_dua.jpg"
                      alt="Image"
                    />
                  </div>
                  <div class="col-md-8">
                    <div class="card-body text-left">
                      <h5 class="card-title">
                        {{ $t("section_1.item_16.title") }}
                      </h5>
                      <p
                        class="card-text"
                        v-html="$t('section_1.item_16.content')"
                      ></p>
                      <p class="card-text">
                        <small class="text-muted"></small>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card mb-3">
                <div class="row no-gutters">
                  <div class="col-md-4">
                    <img
                      src="~assets/project_images/accommodation/Howard.jpg"
                      alt="Image"
                    />
                  </div>
                  <div class="col-md-8">
                    <div class="card-body text-left">
                      <h5 class="card-title">
                        {{ $t("section_1.item_4.title") }}
                      </h5>
                      <p
                        class="card-text"
                        v-html="$t('section_1.item_4.content')"
                      ></p>
                      <p class="card-text">
                        <small class="text-muted"></small>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card mb-3">
                <div class="row no-gutters flex-row-reverse">
                  <div class="col-md-4">
                    <img
                      src="~assets/project_images/accommodation/marriott.jpg"
                      alt="Image"
                    />
                  </div>
                  <div class="col-md-8">
                    <div class="card-body text-left">
                      <h5 class="card-title">
                        {{ $t("section_1.item_14.title") }}
                      </h5>
                      <p
                        class="card-text"
                        v-html="$t('section_1.item_14.content')"
                      ></p>
                      <p class="card-text">
                        <small class="text-muted"></small>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card mb-3">
                <div class="row no-gutters">
                  <div class="col-md-4">
                    <img
                      src="~assets/project_images/accommodation/H2O.png"
                      alt="Image"
                    />
                  </div>
                  <div class="col-md-8">
                    <div class="card-body text-left">
                      <h5 class="card-title">
                        {{ $t("section_1.item_7.title") }}
                      </h5>
                      <p
                        class="card-text"
                        v-html="$t('section_1.item_7.content')"
                      ></p>
                      <p class="card-text">
                        <small class="text-muted"></small>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import SmoothScroll from "smooth-scroll";
export default {
  name: "FacilityBody",
  data() {
    return {
      selected: null,
      options: [
        {
          value: null,
          text: "預計的人數 / 攤位數",
        },
        {
          value: "a",
          text: "This is First option",
        },
        {
          value: "b",
          text: "Selected Option",
        },
        {
          value: {
            C: "3PO",
          },
          text: "This is an option with object value",
        },
        {
          value: "d",
          text: "This one is disabled",
          disabled: true,
        },
      ],
    };
  },
  methods: {
    goAnchor(selector) {
      const scroll = new SmoothScroll();
      const anchor = document.querySelector(selector);
      scroll.animateScroll(anchor, null, {
        //updateURL: false,
        speed: 800,
      });
    },
  },
};
</script>

<style scoped>
.facility-section .card-body {
  min-height: 350px;
}
.solutions-area.section-width .container {
  max-width: 1000px;
}

.facility-section {
  margin-top: 40px;
  text-align: center;
}

.blog-content {
  text-align: left;
}

.single-blog-post .blog-content h3 {
  margin-bottom: 13px;
}

.card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.block2 .card img {
  width: 100%;
}

.card-footer {
  font-size: 16px;
}

.single-blog-post img {
  width: 300px;
  height: 300px;
  object-fit: cover;
}

:target:before {
  content: "";
  display: block;
  height: 80px;
}

hr {
  background-color: #000000;
  height: 3px;
  border: none;
  margin: 30px 0;
}

.cover-image {
  margin-bottom: 50px;
  width: 100%;
  height: 400px;
}

.fs-btn {
  margin-left: 30px;
  margin-bottom: 30px;
  padding-left: 30px;
  padding-right: 30px;
}
</style>
